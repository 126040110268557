html,
body,
#app,
#app > div {
    height: 100%;
}

* {
    -webkit-overflow-scrolling: touch;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        /* Position the tooltip */
        position: absolute;
            right: 45px;
        align-self: flex-end;
        z-index: 1;
    }

    .tooltip:active .tooltiptext {
        visibility: visible;
    }


.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    align-self: center;
    justify-self: center;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

input {
    outline: none;
}

    input[type='checkbox'] {
        width: 4vh;
        height: 4vh;
        max-width: 25px;
        max-height: 25px;
        margin: 0;
        background-color: #e6e6e6;
        border: 1px solid black;
        border-radius: 3px;
        cursor: pointer;
        -moz-transform: scale(1.75);
    }

        input[type='checkbox']:hover {
            width: 4vh;
            height: 4vh;
            max-width: 25px;
            max-height: 25px;
            margin: 0;
            border: 1px solid black !important;
            border-radius: 3px;
            cursor: pointer;
            -moz-transform: scale(1.75);
            /*outline: 1px solid $default-color;*/
            /*-moz-appearance: none;*/
        }

/* class applies to select element itself, not a wrapper element */
.select-css {
    display: block;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid black;
    border-radius: 3;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #efecf7;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

	*/
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #efecf7 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.65em auto, 100%;
}
    /* Hide arrow icon in IE browsers */
    .select-css::-ms-expand {
        display: none;
    }
    /* Hover style */
    .select-css:hover {
        border-color: black;
    }
    /* Focus style */
    .select-css:focus {
        border-color: black;
        /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    }

    /* Set options to normal weight */
    .select-css option {
        font-weight: normal;
        width: 100%;
    }

        .select-css option:hover {
            background-color: '#fff';
        }

body {
    background-color: #592d86;
}

/* ::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
/*  background: transparent; /* optional: just make scrollbar invisible */
/*}
/* optional: show position indicator in transparent */
/* ::-webkit-scrollbar-thumb {
  background: #00000000;
} */

#app {
    font-family: arial;
}

/* .customScrollbar::-webkit-scrollbar {
  width: 15px; /* remove scrollbar space
  background: #525252; /* optional: just make scrollbar invisible
} */

/* .customScrollbar::-webkit-scrollbar-thumb:vertical {
  background: #ffffff;
  border: 1px solid rgb(0, 0, 0);
} */

.header {
    position: fixed;
    left: 0;
    height: 80;
    top: 0;
    width: 100%;
    text-align: center;
}

.footer {
    position: fixed;
    height: 50;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.scrollable {
    overflow: 'auto';
}

.btn {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    margin: 2px 0;
    border: solid 1px transparent;
    border-radius: 4px;
    padding: 0.5em 1em;
    color: #ffffff;
    background-color: #9555af;
}

/*Modal*/
.modal-overlay-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal-content-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    overflow: auto;
    text-align: center;
    padding: 4px;
    cursor: pointer;
}

    .modal-content-div:after {
        vertical-align: middle;
        display: inline-block;
        height: 100%;
        margin-left: -0.05em;
        content: '';
    }

.modal-dialog-div {
    position: relative;
    outline: 0;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    max-width: auto;
    cursor: default;
    border-radius: 4px;
}
/*Modal*/



/* loading dots */

p.loading {
    /*font: 300 4em/150% Impact;*/
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: black;
    /*text-align: center;
    margin: 0;
    padding: 0;*/

}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }

    40% {
        color: white;
        text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }

    60% {
        text-shadow: .25em 0 0 black, .5em 0 0 rgba(0,0,0,0);
    }

    80%, 100% {
        text-shadow: .25em 0 0 black, .5em 0 0 black;
    }
}
